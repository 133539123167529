import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import tooltipPreviewData from '../../../data/previews/tooltip.yml';
import pageHeroData from '../../../data/pages/components.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const IconInformation = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconInformation;
    return <Component {...props} />;
  },
});

const IconPlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPlay;
    return <Component {...props} />;
  },
});

const Tooltip = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Tooltip;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'instruction',
      triggerType: 'icons',
      position: 'top',
      delayType: 'immediate',
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Tooltip"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Tooltip" />
        <V5Notice
          componentName="Tooltip"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-tooltip--docs"
        />

        <PageNavigation
          links={[
            'Options',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Options">
          <SectionSubhead>Types</SectionSubhead>
          <Paragraph>
            Depending on the context, a tooltip may need to act a specific way.
            The instructional type allows for more context, while the label
            clearly states the action performed.
          </Paragraph>
          <ComponentPreview
            name="TooltipType"
            layout="row"
            previewData={tooltipPreviewData.types}
            onChange={() => {
              this.setState({
                type:
                  this.state.type === 'instruction' ? 'label' : 'instruction',
              });
            }}>
            {this.state.type === 'instruction' && (
              <div ignoreThisComponent>
                <Tooltip type="instruction" />
                <div data-tip="Log in with the email address associated with your school.">
                  <IconInformation size="large" />
                </div>
              </div>
            )}
            {this.state.type === 'label' && (
              <div ignoreThisComponent>
                <Tooltip type="label" />
                <div data-tip="Play">
                  <IconPlay size="large" />
                </div>
              </div>
            )}
          </ComponentPreview>

          <SectionSubhead>Triggers</SectionSubhead>
          <ComponentPreview
            name="TooltipTrigger"
            layout="row"
            previewData={tooltipPreviewData.triggers}
            onChange={() => {
              this.setState({
                triggerType:
                  this.state.triggerType === 'icons' ? 'buttons' : 'icons',
              });
            }}>
            {this.state.triggerType === 'icons' && (
              <div ignoreThisComponent>
                <Tooltip type="instruction" />
                <div data-tip="Track each athlete's viewing activity to make sure everyone's on the same page.">
                  <IconInformation size="large" />
                </div>
              </div>
            )}
            {this.state.triggerType === 'buttons' && (
              <div ignoreThisComponent>
                <Tooltip type="instruction" />
                <Button
                  type="primary"
                  size="medium"
                  data-tip="Share this video with individual coaches and athletes or custom groups.">
                  Share
                </Button>
              </div>
            )}
          </ComponentPreview>

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            The tooltip’s position is dictated by the interface design. Avoid
            hiding other key elements or getting cut off by an edge.
          </Paragraph>
          <ComponentPreview
            name="TooltipPosition"
            layout="row"
            previewData={tooltipPreviewData.position}
            onChange={e => {
              this.setState({
                position: e,
              });
            }}>
            <div ignoreThisComponent>
              <Tooltip />
              <div
                data-tip="Track each athlete's viewing activity to make sure everyone's on the same page."
                data-place={this.state.position}>
                <IconInformation size="large" />
              </div>
            </div>
          </ComponentPreview>

          <SectionSubhead>Delay</SectionSubhead>
          <ComponentPreview
            name="TooltipDelay"
            layout="row"
            previewData={tooltipPreviewData.delay}
            onChange={() => {
              this.setState({
                delayType:
                  this.state.delayType === 'immediate'
                    ? 'delayed'
                    : 'immediate',
              });
            }}>
            {this.state.delayType === 'immediate' && (
              <div ignoreThisComponent>
                <Tooltip delayTime={0} />
                <div data-tip="Track each athlete's viewing activity to make sure everyone's on the same page.">
                  <IconInformation size="large" />
                </div>
              </div>
            )}
            {this.state.delayType === 'delayed' && (
              <div ignoreThisComponent>
                <Tooltip delayTime={750} />
                <div data-tip="Track each athlete's viewing activity to make sure everyone's on the same page.">
                  <IconInformation size="large" />
                </div>
              </div>
            )}
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Tooltips should not be used on mobile. Instead, use a{' '}
            <Link href="/components/notifications/note/design">note</Link> to
            explain what the user needs to know.{' '}
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Hover Required</SectionSubhead>
          <Paragraph>
            The tooltip appears on hover by default, but should also be
            triggered on focus.{' '}
            <strong>Tooltips should never appear without a trigger,</strong>{' '}
            especially not as onboarding prompts.
          </Paragraph>

          <SectionSubhead>Type Treatments</SectionSubhead>
          <Paragraph>
            There’s a hard limit of <strong>one</strong> bold or italicized
            phrase—not complete sentence—per tooltip. Use it to call out another
            element (clips, playlists, etc.) or the desired action.
          </Paragraph>
          <DontDo
            dontText="bold an entire tooltip."
            doText="bold or italicize a single important piece of information."
            imgFilename="tooltip-bold"
          />

          <SectionSubhead>Metadata</SectionSubhead>
          <Paragraph>
            Don’t use a tooltip to display metadata, like viewing activity for a
            specific team member. The content should relate directly to the
            interface element, not provide new information with zero context.
          </Paragraph>
          <DontDo
            dontText="pack the tooltip with data they may not expect."
            doText="center the content around the trigger itself."
            imgFilename="tooltip-metadata"
          />

          <SectionSubhead>On Links</SectionSubhead>
          <Paragraph>
            Because we{' '}
            <Link href="/components/link/design#Microcopy">
              link the action
            </Link>{' '}
            and/or where a link takes the user, there’s no need to include a
            tooltip where that same information would be shared.{' '}
            <strong>Avoid tooltips on links altogether.</strong>
          </Paragraph>

          <SectionSubhead>In Forms</SectionSubhead>
          <Paragraph>
            Tooltips should not replace{' '}
            <Link href="/components/forms/input/design/#Microcopy">
              help text
            </Link>{' '}
            on form inputs—the help text should communicate exactly what the
            user is submitting.
          </Paragraph>
          <Paragraph>
            Radios and checkboxes, however, can use tooltips to communicate what
            a user is <em>selecting</em>.
          </Paragraph>
          <DontDo
            dontText="use a tooltip in addition to help text."
            doText="help the user understand their decision."
            imgFilename="tooltip-forms"
          />

          <SectionSubhead>Disabled Buttons</SectionSubhead>
          <Paragraph>
            Use a tooltip to explain why the button is disabled and provide
            clear direction for moving forward.
          </Paragraph>
          <DontDo
            dontText="simply say that the button is disabled."
            doText="provide more context around what enables the button."
            imgFilename="tooltip-disabledbutton"
          />

          <SectionSubhead>Instructional vs. Label</SectionSubhead>
          <Paragraph>
            The tooltip type is defined by the information it's presenting.
          </Paragraph>
          <DontDo
            dontText="use an informational tooltip to simply name an icon."
            doText="use a label when no additional instructions are needed."
            imgFilename="iconlabel-tooltip"
          />

          <DontDo
            dontText="make the label a complete sentence."
            doText="make it a single action and stick to UI label guidelines."
            imgFilename="iconlabel-sentence"
          />

          <Paragraph>
            <em>
              Abiding by{' '}
              <Link href="http://hudl.com/design/writing/writing-for/teams/product">
                UI label guidelines
              </Link>{' '}
              also means no icon label should have closing punctuation.
            </em>
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            <strong>Tooltips should be no longer than 110 characters.</strong>{' '}
            That comes to four full lines for an effective message.
          </Paragraph>

          <DontDo
            dontText="ramble and take up a quarter of the interface for a quick FYI."
            doText="be as clear and concise as possible."
            imgFilename="tooltip-contentlength"
          />

          <SectionSubhead>Be Direct</SectionSubhead>
          <Paragraph>
            Tooltips don’t require fun phrases or a full shot of personality,
            just tell the user exactly what they need to know. <em>Inform</em>{' '}
            them and move on.
          </Paragraph>
          <DontDo
            dontText="go crazy with a casual tone and miss the point."
            doText="focus on the most necessary information."
            imgFilename="tooltip-direct"
          />

          <SectionSubhead>Linked Text</SectionSubhead>
          <Paragraph>
            A tooltip should never take the user somewhere else—all necessary
            information should live in the tooltip itself (for the sake of
            context more than anything). <strong>Don’t link the text</strong>.
          </Paragraph>

          <SectionSubhead>Punctuation</SectionSubhead>
          <Paragraph>
            <strong>Only periods are allowed in tooltips.</strong> We shouldn’t
            use the limited space to yell with an exclamation or ask questions
            with no answer.
          </Paragraph>
          <DontDo
            dontText="ask a question when we can't fit an answer."
            doText="use a period to instill confidence in the complete thought."
            imgFilename="tooltip-punctuation"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Tooltip" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
